<template>
  <div>
    <c-table
      title="LBLBASEINFO"
      class="q-mb-md"
      :columns="grid.columns"
      :data="grid.data"
      customDataTr
      gridHeightAuto
      hideHeader
      hideBottom
      :isTitle="true"
      :columnSetting="false"
      :isFullScreen="false"
      :isExcelDown="false"
      :filtering="false"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-qr-btn
            v-if="editable"
            :mobileUrl="'/hea/etr/satisfactionSurvey?heaEtrParticipantStatusId='+ survey.heaEtrParticipantStatusId"
          ></c-qr-btn>
          <c-btn 
            v-show="editable && !disabled" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="survey"
            mappingType="PUT"
            label="LBLSAVE" 
            icon="save"
            @beforeAction="saveSurvey"
            @btnCallback="saveSurveyCallback" />
          <c-btn 
            v-show="editable && !disabled" 
            :isSubmit="isComplete"
            :url="completeUrl"
            :param="survey"
            mappingType="PUT"
            label="설문완료" 
            icon="check"
            @beforeAction="completeSurvey"
            @btnCallback="completeSurveyCallback" />
          <c-btn 
            v-show="cancelBtnEditable" 
            :isSubmit="isCancel"
            :url="cancelUrl"
            :param="survey"
            mappingType="PUT"
            label="다시작성" 
            icon="keyboard_return"
            @beforeAction="cancelSurvey"
            @btnCallback="cancelSurveyCallback" />
        </q-btn-group>
      </template>
      <template v-slot:customDataTr="props">
        <q-tr :props="props" v-if="props.rowIndex===0" no-hover>
          <q-td
            class="text-center th-td-style th-style th-style-1"
            :rowspan="1">
            <span>
              팀
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style td-style-1"
            :rowspan="1">
            <span>
              {{survey.upDeptName}}
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style th-style th-style-1"
            :rowspan="1">
            <span>
              반
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style td-style-1"
            :rowspan="1">
            <span>
              {{survey.deptName}}
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style th-style th-style-1"
            :rowspan="1">
            <span>
              성명
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style td-style-1"
            :rowspan="1">
            <span>
              {{survey.userName}}
            </span>
          </q-td>
        </q-tr>
        <q-tr :props="props" v-if="props.rowIndex===1" no-hover>
          <q-td
            class="text-center th-td-style th-style th-style-1"
            :rowspan="1">
            <span>
              연령
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style td-style-1"
            :rowspan="1">
            <span>
              {{survey.age}}
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style th-style th-style-1"
            :rowspan="1">
            <span>
              근속년수
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style td-style-1"
            :rowspan="1">
            <span>
              {{survey.longevityCount}}
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style th-style th-style-1"
            :rowspan="1">
            <span>
              작성일자
            </span>
          </q-td>
          <q-td
            class="text-center th-td-style td-style-1"
            :rowspan="1">
            <c-datepicker
              class="survey-custom-date"
              :disabled="true"
              :editable="editable"
              label=""
              default="today"
              name="writeDate"
              v-model="survey.writeDate">
            </c-datepicker>
          </q-td>
        </q-tr>
      </template>
    </c-table>
    <q-form ref="editForm">
      <c-card v-if="checkboxItems && checkboxItems.length > 0" class="cardClassDetailForm" title="체크리스트">
        <template slot="card-detail">
          <div class="col-12" v-for="(data, idx) in checkboxItems" :key="idx">
            <div class="row" v-if="data.isText">
              <div class="col-6">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  :label="data.checklistName"
                  name="acheValue"
                  v-model="data.acheValue" />
              </div>
              <div class="col-2">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  :rows="9"
                  type="number"
                  label="참여 전"
                  name="beforeValue"
                  v-model="data.beforeValue" />
              </div>
              <div class="col-2">
                <c-text
                  required
                  :editable="editable"
                  :disabled="disabled"
                  type="number"
                  label="참여 후"
                  name="value"
                  v-model="data.value" />
              </div>
            </div>
            <c-textarea
              v-else-if="data.isTextArea"
              :editable="editable"
              :disabled="disabled"
              :rows="9"
              :label="data.checklistName"
              beforeText=""
              name="checklist9"
              v-model="data.value" />
            <c-radio
              v-else
              required
              :disabled="disabled"
              :editable="editable"
              :isArray="false"
              :comboItems="answerItems"
              itemText="codeName"
              itemValue="code"
              valueText="codeName"
              valueKey="code"
              :label="data.checklistName"
              :name="`value${idx}`"
              v-model="data.value"
            />
          </div>
        </template>
      </c-card>
    </q-form>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'satisfactionSurveyDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        heaEtrParticipantStatusUserId: '',
        surveies: [],
        readOnly: false,
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      answerItems: [
        { code: '5', codeName: '매우 적절' },
        { code: '4', codeName: '적절' },
        { code: '3', codeName: '보통' },
        { code: '2', codeName: '부적절' },
        { code: '1', codeName: '매우 부적절' },
      ],
      survey: {
        heaEtrParticipantStatusId: '',  // 참여자 현황 일련번호
        heaEtrParticipantStatusUserId: '',  // 참여자 현황 user 일련번호
        userId: '',  // 대상자 id
        userName: '',  // 이름
        deptName: '',  // 반
        upDeptName: '',  // 팀
        age: '',  // 연령
        longevityCount: '',  // 근속년수
        overlappingArea: '',  // 중복부위
        treatmentArea: '',  // 치료부위
        participationCategory: '',  // 참여구분
        treatmentStartDate: '',  // 치료기간 시작일
        treatmentEndDate: '',  // 치료기간 종료일
        checklist1: '',  // 체크리스트 1번문항
        checklist2: '',  // 체크리스트 2번문항
        checklist3: '',  // 체크리스트 3번문항
        checklist4: '',  // 체크리스트 4번문항
        checklist5: '',  // 체크리스트 5번문항
        checklist6: '',  // 체크리스트 6번문항
        checklist7: '',  // 체크리스트 7번문항
        checklist8: '',  // 체크리스트 8번문항
        checklist9: '',  // 체크리스트 9번문항
        checklist1Anw: '',  // 체크리스트 1번 답
        checklist2Anw: '',  // 체크리스트 2번 답
        checklist3Anw: '',  // 체크리스트 3번 답
        checklist4Anw: '',  // 체크리스트 4번 답
        checklist5Anw: '',  // 체크리스트 5번 답
        checklist6AcheArea: '',  // 체크리스트 6번 통증부위
        checklist6BeforeScore: '',  // 체크리스트 6번 치료전
        checklist6AfterScore: '',  // 체크리스트 6번 치료후
        checklist7Anw: '',  // 체크리스트 7번 답
        checklist8Anw: '',  // 체크리스트 8번 답
        checklist9Anw: '',  // 체크리스트9번 답
        regUserId: '',  // 등록자
        regDt: '',  // 등록일시
        chgUserId: '',  // 수정자
        chgDt: '',  // 수정일시
        attendanceFlag: '',  // 출석여부
        finalScore: '',  // 최종점수
        completeFlag: 'N',
        writeDate: '',
      },
      grid: {
        columns: [
          {
            name: 'header1',
            field: 'header1',
            label: 'header1',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
          {
            name: 'data1',
            field: 'data1',
            label: 'data1',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
          {
            name: 'header2',
            field: 'header2',
            label: 'header2',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
          {
            name: 'data2',
            field: 'data2',
            label: 'data2',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
          {
            name: 'header3',
            field: 'header3',
            label: 'header3',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
          {
            name: 'data3',
            field: 'data3',
            label: 'data3',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
          {
            name: 'header4',
            field: 'header4',
            label: 'header4',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
          {
            name: 'data4',
            field: 'data4',
            label: 'data4',
            align: 'center',
            sortable: false,
            style: 'width:25%',
          },
        ],
        data: [{}, {}, {}, {}, {}, {},],
      },
      checkboxItems: [],
      editable: true,
      isSave: false,
      isComplete: false,
      isCancel: false,
      detailUrl: '',
      checklistUrl: '',
      saveUrl: '',
      completeUrl: '',
      cancelUrl: '',
    };
  },
  computed: {
    disabled() {
      return this.survey.completeFlag === 'Y';
    },
    cancelBtnEditable() {
      return false;
    },
  },
  watch: {
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.hea.etr.satisfaction.get.url
      this.checklistUrl = selectConfig.hea.etr.satisfaction.surveyChecklist.url
      this.saveUrl = transactionConfig.hea.etr.satisfaction.update.url
      this.completeUrl = transactionConfig.hea.etr.satisfaction.complete.url
      this.cancelUrl = transactionConfig.hea.etr.satisfaction.cancel.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      this.$http.url = this.$format(this.detailUrl, this.popupParam.heaEtrParticipantStatusUserId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.survey, _result.data)

        if (!this.survey.writeDate) {
          this.$set(this.survey, 'writeDate', this.$comm.getToday())
        }
        // 체크리스트
        this.getChecklist();
      },);
    },
    getChecklist() {
      this.$http.url = this.checklistUrl
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.survey.plantCd,
        useFlag: 'Y',
      }
      this.$http.request((_result) => {
        /**
         * 체크리스트 처리
         * checkbox형태로 for문으로 형태가 그려짐에 따라 v-model선정처리가 필요
         */
        if (_result.data && _result.data.length > 0) {
          let _idx = 1;
          this.$_.forEach(_result.data, item => {
            if (_idx === 6) {
              item.isText = true
              item.acheValue = this.survey['treatmentArea']
              item.beforeValue = this.survey[`checklist${_idx}BeforeScore`]
              item.value = this.survey[`checklist${_idx}AfterScore`]
            } else if (_idx === 9) {
              item.isTextArea = true
              item.value = this.survey[`checklist${_idx}Anw`]
            } else {
              item.value = this.survey[`checklist${_idx}Anw`]
            }
            _idx++;
          })
          this.checkboxItems = _result.data
        } else {
          this.checkboxItems = []
        }
      },);
    },
    setSaveValue() {
      return new Promise(resolve => {
        this.survey.regUserId = this.$store.getters.user.userId
        this.survey.chgUserId = this.$store.getters.user.userId

        console.log('this.checkboxItems', this.checkboxItems)
        // 체크리스트
        if (this.checkboxItems && this.checkboxItems.length > 0) {
          let _idx = 1;
          this.$_.forEach(this.checkboxItems, item => {
            if (_idx === 6) {
              this.$set(this.survey, `checklist${_idx}AfterScore`, item.value)
            } else {
              this.$set(this.survey, `checklist${_idx}Anw`, item.value)
            }
            _idx++;
          })
        } else {
          this.$set(this.survey, `checklist1Anw`, '')
          this.$set(this.survey, `checklist2Anw`, '')
          this.$set(this.survey, `checklist3Anw`, '')
          this.$set(this.survey, `checklist4Anw`, '')
          this.$set(this.survey, `checklist5Anw`, '')
          this.$set(this.survey, `checklist6AfterScore`, '')
          this.$set(this.survey, `checklist7Anw`, '')
          this.$set(this.survey, `checklist8Anw`, '')
          this.$set(this.survey, `checklist9Anw`, '')
        }

        resolve(true);
      });
    },
    saveValue() {
      return new Promise(resolve => {
        this.isSave = !this.isSave
        resolve(true);
      });
    },
    completeValue() {
      return new Promise(resolve => {
        this.isComplete = !this.isComplete
        resolve(true);
      });
    },
    cancelValue() {
      return new Promise(resolve => {
        this.isCancel = !this.isCancel
        resolve(true);
      });
    },
    saveSurvey() {
      console.log(this.checkboxItems)
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              let promises = [
                {
                  func: this.setSaveValue,
                },
                {
                  func: this.saveValue
                },
              ];
              this.$comm.orderedPromise(promises);
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveSurveyCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    completeSurvey() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: '설문완료 하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              let promises = [
                {
                  func: this.setSaveValue,
                },
                {
                  func: this.completeValue
                },
              ];
              this.$comm.orderedPromise(promises);
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeSurveyCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    cancelSurvey() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: '다시작성 하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              let promises = [
                {
                  func: this.setSaveValue,
                },
                {
                  func: this.cancelValue
                },
              ];
              this.$comm.orderedPromise(promises);
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    cancelSurveyCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getDetail();
    },
    closePopup(data) {
      this.$emit('closePopup', data);  
    },
  }
};
</script>
<style lang="sass">
.th-td-style
  vertical-align: middle !important

.th-style
  background: #EEEEEE !important
  font-weight: 700

.th-style-1
  width: 10%

.td-style-1
  width: 15%

.td-style-2
  width: 65%

.td-style-3
  width: 90%

.survey-custom-date 
  .cdatetimepic.mx-datepicker
    height: 33px !important

.custom-text-before-parent
  .custom-text-before
    font-size: 12px !important
    height: 22px !important
    min-height: 22px !important
    line-height: 22px
  .q-field--dense .q-field__inner
    padding-bottom: 0 !important
    padding-top: 3px !important
  .customText.q-field--dense .q-field__control, .customText.q-field--dense .q-field__marginal
    height: 22px !important
    min-height: 22px !important

.lastQue
  label
    padding-bottom: 0px !important
</style>